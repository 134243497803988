import { NavLink } from "react-router-dom";
import ControlPanel from "/src/components/ControlPanel";
import Symbol from "../Symbol";
import { useTranslation } from "react-i18next";

export default function Header(props) {
  const { t } = useTranslation();
  const {
    viewMode,
    toggleViewMode,
    showControlPanel,
    toggleControlPanel,
    npcSwitchRate,
    updateNpcSwitchRate,
    toggleInsideSimMode,
    insideSimMode,
  } = props;
  return (
    <header>
      <div className="title-wrapper">
        <h1 className="main-title">
          D2 <span className="atlas">/ˈatləs/</span>
        </h1>
        <h2>{t("misc.subheader")}</h2>
      </div>

      <menu>
        <li>
          <NavLink to={"/"}>Calculator</NavLink>
        </li>
        <li>
          <NavLink to={"/inside-simulator"}>Inside Simulator</NavLink>
        </li>
        {/* <li>
          <NavLink to={"/outside-simulator"}>Outside Simulator</NavLink>
        </li> */}
        <li onMouseUp={toggleControlPanel}>
          <Symbol
            symbol={"settings"}
            isSelected={() => {}}
            canSelect={true}
            isDisabled={() => {}}
            isFinal={false}
            handleClick={() => {}}
            isInline={true}
            withText={false}
            withBrackets={false}
          />
        </li>
      </menu>

      {showControlPanel && (
        <ControlPanel
          showControlPanel={showControlPanel}
          toggleControlPanel={toggleControlPanel}
          toggleViewMode={toggleViewMode}
          npcSwitchRate={npcSwitchRate}
          updateNpcSwitchRate={updateNpcSwitchRate}
          toggleInsideSimMode={toggleInsideSimMode}
          insideSimMode={insideSimMode}
          viewMode={viewMode === "visual" ? "instruction" : "visual"}
        />
      )}
    </header>
  );
}
