import i18n from "../i18n";
import { lngs } from "../i18n";
import { Trans, useTranslation } from "react-i18next";
import images from "../assets/images";

export default function ControlPanel(props) {
  const {
    toggleViewMode,
    viewMode,
    showControlPanel,
    toggleControlPanel,
    npcSwitchRate,
    updateNpcSwitchRate,
    toggleInsideSimMode,
    insideSimMode,
  } = props;
  const { t } = useTranslation();

  const langChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const textKey = viewMode === "visual" ? "showVisualSteps" : "showTextSteps";

  const renderLanguageDropdown = () => {
    return (
      <select onChange={langChange}>
        {Object.keys(lngs).map((lng) => (
          <option
            key={lng}
            selected={i18n.resolvedLanguage === lng}
            value={lng}
          >
            {lngs[lng].nativeName}
          </option>
        ))}
      </select>
    );
  };

  const renderNPCSwitchRate = () => {
    const options = [1000, 2500, 5000, 10000, 20000];

    return (
      <select onChange={updateNpcSwitchRate}>
        {options.map((speed) => (
          <option
            key={`npc-switch-${speed}`}
            selected={npcSwitchRate === speed}
            value={speed}
          >
            {`${t("control.every")} ${speed / 1000} ${t("control.seconds")}`}
          </option>
        ))}
      </select>
    );
  };

  return (
    <div className={`control-panel ${showControlPanel ? "open" : ""}`}>
      <h2>
        <Trans i18nKey={`control.title`}>Control Panel</Trans>
      </h2>
      <img
        className="close-icon"
        onMouseUp={toggleControlPanel}
        src={images.cross}
        alt="Close Control Panel"
      />
      <div className="options">
        <div className="control-option">
          <span className="label">
            <Trans i18nKey={"control.calculatorViewMode"}></Trans>
          </span>
          <button onMouseUp={() => toggleViewMode()}>
            <Trans i18nKey={`control.${textKey}`}></Trans>
          </button>
        </div>
        <div className="control-option">
          <span className="label">
            <Trans i18nKey={"control.language"}></Trans>
          </span>
          {renderLanguageDropdown()}
        </div>
        <div className="control-option">
          <span className="label">
            <Trans i18nKey={"control.npcSwitchRate"}></Trans>
          </span>
          {renderNPCSwitchRate()}
        </div>
        <div className="control-option">
          <span className="label">
            <Trans i18nKey={"control.insideSimModeOption"}></Trans>
          </span>
          <button onMouseUp={() => toggleInsideSimMode()}>
            {t(
              `control.${
                insideSimMode === "automatic" ? "manual" : "automatic"
              }`
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
