import { useTranslation } from "react-i18next";
import Symbol from "./Symbol";
export default function InstructionStep(props) {
  const { t } = useTranslation();
  const { symbol, position } = props;
  return (
    <li>
      <div>
        <span>{`${t("positions.dissect")} `}</span>

        <Symbol
          symbol={symbol}
          isSelected={() => {}}
          canSelect={false}
          isDisabled={() => {}}
          isFinal={false}
          isInline={true}
          handleClick={() => {}}
          withText={true}
          withBrackets={true}
        />
        <span>{t(`positions.into${position}`)}</span>
      </div>
    </li>
  );
}
