import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useInterval from "../hooks/UseInterval";
import {
  getRandomInt,
  shapes,
  shuffleArray,
  positions,
  calculateHeldShape,
  buildStartingStackValuesObject,
  pickUserHeldShapePositions,
  dedupePopulatedArrayValues,
} from "../helper";
import { outputLog } from "../logger";
import { useRootContext } from "./Root";
import Buff from "../components/Simulator/Buff";
import Timer from "../components/Simulator/Timer";
import DeathScreen from "../components/Simulator/DeathScreen";
import Reset from "../components/Reset";
import Ogre from "../components/Simulator/Ogre";
import Knight from "../components/Simulator/Knight";
import Statue from "../components/Simulator/Statue";
import WallShadow from "../components/Simulator/WallShadow";
import InputHistory from "../components/Simulator/InputHistory";
import images from "../assets/images";
const encounterStartTimeSeconds = 208;
const debug = false;
const pauseTime = false;
const reviveOffsetSeconds = 30;

export function InsideSimulator() {
  const { t } = useTranslation();
  // const [updateTutorialStep, setShowTutorial] = useState(
  //   localStorage.getItem("showSoloSimTutorial") ?? true
  // );

  const { npcSwitchRate, insideSimMode } = useRootContext();

  const [statueMap, setStatueMap] = useState(shuffleArray(shapes));
  const [shapeMap, setShapeMap] = useState(
    buildStartingStackValuesObject(statueMap, shuffleArray(shapes))
  );
  const [knightKillCount, setKnightKillCount] = useState(0);
  const [encounterTimer, setEncounterTimer] = useState(
    encounterStartTimeSeconds
  );
  const [hasFailed, setHasFailed] = useState(false);
  const [allShapesSetCorrectly, setAllShapesSetCorrectly] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);
  const [hasEscaped, setHasEscaped] = useState(false);
  const [isHoldingAllShapes, setIsHoldingAllShapes] = useState(false);
  const [userPosition, setUserPosition] = useState(
    getRandomInt(0, statueMap.length - 1)
  );
  const [resetCount, updateResetCount] = useState(0);
  const [successCount, updateSuccessCount] = useState(0);
  const [successStreak, updateSuccessStreak] = useState(0);
  const [givenAwayCount, updateGivenAwayCount] = useState(0);
  const [thisTimeGivenAwayCount, updateThisTimeGivenAwayCount] = useState(0);
  const [iterationCount, setIterationCount] = useState(0);
  const [allShapesMatchingStatue, setAllShapesMatchingStatue] = useState(false);
  const [shapesMovedByTeam, updateShapesMovedByTeam] = useState(0);
  const [reviveTime, setReviveTime] = useState(0);
  const [hasFinalized, setHasFinalized] = useState(false);
  const [hasRevived, setHasRevived] = useState(false);

  const [activeShape, setActiveShape] = useState("empty-stack");
  const [rotationActive, setRotationActive] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(null);
  const [inputHistory, setInputHistory] = useState([]);
  const [rowActionOverlay, setRowActionOverlay] = useState(null);
  const [currentKnightOptions, setCurrentKnightOptions] = useState(
    pickUserHeldShapePositions(shapeMap, userPosition)
  );

  useEffect(() => {
    const allStatuesCompleted = statueMap.every((statue, statueIndex) => {
      const allMatching = shapeMap.filter(
        (shapeData) =>
          shapeData.index === statueIndex &&
          shapeData.symbol !== statue &&
          shapeData.hasTransferred === true
      );

      return allMatching.length === 2;
    });
    setAllShapesSetCorrectly(allStatuesCompleted);
  }, [shapeMap]);

  useEffect(() => {
    if (
      (allShapesMatchingStatue && allShapesSetCorrectly && hasEscaped) ||
      (insideSimMode === "manual" && hasEscaped)
    ) {
      setHasCompleted(true);
      updateSuccessCount(successCount + 1);
      updateSuccessStreak(successStreak + 1);
    }
  }, [shapeMap, allShapesSetCorrectly, hasEscaped]);

  useEffect(() => {
    if (
      !allShapesMatchingStatue &&
      shapeMap.every((shape) => shape.symbol === statueMap[shape.index])
    ) {
      setAllShapesMatchingStatue(true);
    }
  }, [shapeMap, allShapesMatchingStatue]);

  useEffect(() => {
    if (shapesMovedByTeam >= 6 && hasFinalized === false) {
      setHasFinalized(true);
      setReviveTime(Math.max(encounterTimer - reviveOffsetSeconds, 0));
    }
  }, [shapesMovedByTeam, hasFinalized]);

  useEffect(() => {
    if (hasFinalized && !hasRevived && encounterTimer <= reviveTime) {
      markAsRevived();
    }
  }, [hasFinalized, hasRevived, encounterTimer]);

  useEffect(() => {
    const userHeldShapes = shapeMap
      .reduce((shapes, shape) => {
        return shape.index === userPosition && shape.isHeld === true
          ? [...shapes, shape]
          : [...shapes, ""];
      }, [])
      .filter((val) => val !== "");

    setIsHoldingAllShapes(userHeldShapes.length === 2);
  }, [shapeMap]);

  const userShapes = shapeMap.reduce((shapes, shape, index) => {
    return shape.index === userPosition ? [...shapes, shape] : [...shapes, ""];
  }, []);

  const activeUserShapes = userShapes.filter((shape) => shape !== "");

  const resetEncounter = () => {
    setEncounterTimer(encounterStartTimeSeconds);
    updateShapesMovedByTeam(0);
    if (hasFailed === true || hasCompleted === false) {
      updateSuccessStreak(0);
    }
    setHasFailed(false);
    setHasEscaped(false);
    setHasCompleted(false);
    setHasRevived(false);
    setReviveTime(0);
    setHasFinalized(false);
    setIsHoldingAllShapes(false);
    const statueValues = shuffleArray(shapes);
    const newShapeMap = buildStartingStackValuesObject(
      statueValues,
      shuffleArray(shapes)
    );
    setKnightKillCount(0);
    updateThisTimeGivenAwayCount(0);
    setStatueMap(statueValues);
    setShapeMap(newShapeMap);
    setRotationActive(false);
    const newUserPosition = getRandomInt(0, statueValues.length - 1);
    setUserPosition(newUserPosition);
    updateResetCount(resetCount + 1);
    setActiveShape("empty-stack");
    setIterationCount(0);
    setAllShapesMatchingStatue(false);
    setInputHistory([]);
    setRowActionOverlay(null);
    setCurrentKnightOptions(
      pickUserHeldShapePositions(newShapeMap, newUserPosition)
    );
  };

  const shapeRotate = () => {
    setIterationCount(iterationCount + 1);
    const shouldRotateStack = activeUserShapes.length > 1;
    setRotationActive(shouldRotateStack);
    if (shouldRotateStack) {
      setActiveShape(
        activeUserShapes[iterationCount % activeUserShapes.length].symbol
      );
    }

    if (activeUserShapes.length === 1) {
      setActiveShape(activeUserShapes[0].symbol);
    }

    if (activeUserShapes.length === 0) {
      setActiveShape("empty-stack");
    }
  };

  useInterval(shapeRotate, 1500);

  const timerCountdown = () => {
    if (encounterTimer > 0) {
      setEncounterTimer(encounterTimer - 1);
      return;
    }

    if (!hasFailed) {
      setHasFailed(true);
    }
  };
  useInterval(
    timerCountdown,
    hasCompleted || pauseTime || tutorialStep !== null ? null : 1000
  );

  // Given the provided statue index, establish the held shapes and whether any of them should be sent away, and to which statue
  const dunkNPCShape = (statueIndex) => {
    const statueShape = statueMap[statueIndex];
    const heldShapes = shapeMap.reduce((shapes, shape) => {
      return shape.index === statueIndex ? [...shapes, shape] : [...shapes, ""];
    }, []);
    const populatedHeldShapes = heldShapes.filter((val) => val !== "");

    if (populatedHeldShapes.length === 0) {
      outputLog(`No shapes held by statue. Skipping`);
      return;
    }

    if (
      populatedHeldShapes.length === 2 &&
      populatedHeldShapes.every(
        (val) => val.hasTransferred && val.symbol !== statueShape
      )
    ) {
      outputLog(
        `${statueShape} statue holds correct symbols to escape! Skipping`
      );
      return;
    }

    // All statues have at some point in this encouter all simultaneously held only their own shapes
    if (allShapesMatchingStatue) {
      /*
      Transfer priority after all matching shapes held

      1. Multiple instances of symbols that do not match statue - 
          One of these will be required on other statues so send it back to the statue that matches for them to sort it correctly
          (only player characters can force a statue to have this state, the NPC statues will always try to resolve this immediately)
      2. shapes that match statue - 
          These are required on other statues, so send them away to the first statue found without a matching symbols
      */

      const nonMatchingShapeToMoveIndex = heldShapes.findIndex(
        (shape, ind) =>
          shape !== "" &&
          shape.symbol !== statueShape &&
          ind !==
            heldShapes.findIndex(
              (matchShape) => matchShape.symbol === shape.symbol
            )
      );

      const matchingShapeToMoveIndex = heldShapes.findIndex(
        (shape) => shape.index === statueIndex && shape.symbol === statueShape
      );

      let shapeToMove = -1;
      if (nonMatchingShapeToMoveIndex > -1) {
        shapeToMove = nonMatchingShapeToMoveIndex;
      }
      if (matchingShapeToMoveIndex > -1) {
        shapeToMove = matchingShapeToMoveIndex;
      }

      const stillHasOwnShapesCount = heldShapes.filter(
        (shapeData) => shapeData.symbol === statueShape
      );

      if (nonMatchingShapeToMoveIndex > -1) {
        outputLog(
          `${statueShape} holds too many of required shape. Need to transfer shape ${nonMatchingShapeToMoveIndex} away`
        );

        return {
          shapeIndex: nonMatchingShapeToMoveIndex,
          statueToDunkOn: statueMap.indexOf(
            shapeMap[nonMatchingShapeToMoveIndex].symbol
          ),
          position: shapeMap[nonMatchingShapeToMoveIndex].position,
        };
      }

      if (stillHasOwnShapesCount.length > 0) {
        outputLog(
          `${statueShape} still holds some of own kind. Need to transfer ${stillHasOwnShapesCount.length} more`
        );

        // Find statue with no symbols of current statue, and give it to them
        const statueWithoutShapeIndex = statueMap.findIndex((val, ind) => {
          if (val === statueShape) {
            return false;
          }

          outputLog(`checking ${val} statue for presence of ${statueShape}`);

          const statueShapeCounts = shapeMap.reduce(
            (shapeCounts, shapeData) => {
              const symb = shapeData.symbol;
              outputLog(
                shapeData,
                `shapeData.index ${shapeData.index} === ind ${ind}`
              );
              return shapeData.index === ind
                ? {
                    ...shapeCounts,
                    [symb]: parseInt(shapeCounts[symb] ?? 0, 10) + 1,
                  }
                : shapeCounts;
            },
            { square: 0, triangle: 0, circle: 0 }
          );

          outputLog(`count found as ${JSON.stringify(statueShapeCounts)}`);
          outputLog(statueShapeCounts[statueShape]);
          outputLog();
          return statueShapeCounts[statueShape] === 0;
        });

        outputLog(`${statueWithoutShapeIndex} statue needs ${statueShape}`);

        return {
          shapeIndex: shapeToMove,
          statueToDunkOn: statueWithoutShapeIndex,
          position: shapeMap[shapeToMove].position,
        };
      }

      outputLog(
        `${statueShape} statue missing additional shapes, pausing until transferred`
      );
      return;
    }

    // If not yet had all statues holding all instances of their own symbols, then try to get to that state

    const allShapesOnThisStatueMatching = heldShapes
      .filter((shape) => shape !== "")
      .every((shape) => shape.symbol === statueShape);
    outputLog(
      `allShapesOnThisStatueMatching for ${statueShape}: ${allShapesOnThisStatueMatching}`
    );

    // If there are symbols to move on this statue, action them
    if (!allShapesOnThisStatueMatching) {
      const shapeToMoveIndex = heldShapes.findIndex(
        (shape) => shape.index === statueIndex && shape.symbol !== statueShape
      );

      outputLog(`shapeToMoveIndex for ${statueShape}: ${shapeToMoveIndex}`);

      if (shapeToMoveIndex > -1) {
        outputLog(
          `Marking shape ${shapeToMoveIndex} as movable from ${shapeMap[shapeToMoveIndex].symbol}`
        );

        return {
          shapeIndex: shapeToMoveIndex,
          statueToDunkOn: statueMap.indexOf(shapeMap[shapeToMoveIndex].symbol),
          position: shapeMap[shapeToMoveIndex].position,
        };
      }
    }

    // If this statue is ready but others aren't then pause until either complete or containing more symbols
    if (!allShapesMatchingStatue && allShapesOnThisStatueMatching) {
      outputLog(
        `Pausing symbol pass from ${statueShape} due to all matching shapes but other statues not ready`
      );
      return;
    }
  };

  const iterateNPCStatues = () => {
    {
      if (hasFinalized === true && hasRevived === false) {
        return;
      }
      const npcShapeIndexes = Object.keys(statueMap)
        .filter((statueIndex) => parseInt(statueIndex, 10) !== userPosition)
        .map((num) => parseInt(num, 10));

      const dunkedShapeMap = [...shapeMap];
      const shapeDunkMap = npcShapeIndexes.map(dunkNPCShape);
      let shapesMoved = 0;
      const shapesMovedList = [];
      const shapePositions = [];
      const shapePositionsToUser = [];
      shapeDunkMap.forEach((shapeData) => {
        if (typeof shapeData === "undefined") {
          return;
        }

        outputLog(shapeData);
        const { shapeIndex, statueToDunkOn, position } = shapeData;

        outputLog(`dunking ${shapeIndex} on ${statueToDunkOn}`);

        dunkedShapeMap[shapeIndex] = {
          ...dunkedShapeMap[shapeIndex],
          index: statueToDunkOn,
          hasTransferred: true,
        };

        shapePositions.push(position);
        if (statueToDunkOn === userPosition) {
          shapePositionsToUser.push(position);
        }

        shapesMovedList.push({
          isPlayer: false,
          shapePositions: [shapeIndex],
          originalStatueIndex: shapeMap[shapeIndex].index,
          statueIndex: statueToDunkOn,
          isSuccessful: true,
        });

        shapesMoved++;
      });

      setInputHistory([...inputHistory, ...shapesMovedList]);

      setCurrentKnightOptions(
        dedupePopulatedArrayValues([
          ...currentKnightOptions,
          ...shapePositionsToUser,
        ])
      );
      updateShapesMovedByTeam(shapesMovedByTeam + shapesMoved);
      setShapeMap(dunkedShapeMap);
    }
  };

  if (debug === false) {
    useInterval(
      iterateNPCStatues,
      insideSimMode === "manual" ||
        allShapesSetCorrectly ||
        tutorialStep !== null
        ? null
        : npcSwitchRate
    );
  }

  const killKnight = (shapeIndex) => {
    const copiedArray = [...shapeMap];
    const shapePosition = shapeMap.findIndex(
      (shape) => shape.position === shapeIndex
    );
    copiedArray[shapePosition].isKnightKilled = true;
    setShapeMap(copiedArray);
    setKnightKillCount(knightKillCount + 1);
  };

  const markShapeAsHeld = (shapeIndex) => {
    const heldShapeMap = [...shapeMap];
    heldShapeMap[shapeIndex].isHeld = true;
    outputLog(`${heldShapeMap[shapeIndex].symbol} marked as held`);
    setShapeMap(heldShapeMap);
  };

  const dunkHeldShapes = (statue) => {
    const heldUserShapes = userShapes.reduce((shapes, shape) => {
      return shape.isHeld ? [...shapes, shape] : [...shapes, ""];
    }, []);

    const matchingShapes = heldUserShapes.filter((shape) => shape !== "");

    if (matchingShapes.length === 0) {
      outputLog("No shapes available for dunk. returning early");
      return;
    }

    const playerDunkedStatueIndex = statueMap.indexOf(statue);
    // 2D shape dunk, initiate swap!
    if (matchingShapes.length === 1) {
      const dunkedShapeMap = [...shapeMap];
      const shapeToUpdate = heldUserShapes.findIndex((shape) => shape !== "");

      outputLog(
        `player chose ${statue} (index ${playerDunkedStatueIndex}) as dunk target`
      );

      // Mark player dunk
      dunkedShapeMap[shapeToUpdate] = {
        ...dunkedShapeMap[shapeToUpdate],
        isHeld: false,
        index: playerDunkedStatueIndex,
        beenDunked: true,
        hasTransferred: true,
      };

      /*
      Transfer priority
      If the shape hasn't been transferred AND it doesn't match statue
      If the shape hasn't been transferred
      */

      setInputHistory([
        ...inputHistory,
        {
          isPlayer: true,
          shapePositions: [shapeToUpdate],
          originalStatueIndex: shapeMap[shapeToUpdate].index,
          statueIndex: playerDunkedStatueIndex,
          isSuccessful: true,
        },
      ]);
      updateGivenAwayCount(givenAwayCount + 1);
      updateThisTimeGivenAwayCount(thisTimeGivenAwayCount + 1);
      updateShapesMovedByTeam(shapesMovedByTeam + 1);
      const updatedKnightOptions = [...currentKnightOptions];
      const shapeToClear = updatedKnightOptions.findIndex(
        (shape) => shape === shapeToUpdate
      );
      updatedKnightOptions[shapeToClear] = "";
      setCurrentKnightOptions(updatedKnightOptions);
      setShapeMap(dunkedShapeMap.map((obj) => ({ ...obj, isHeld: false })));
      if (tutorialStep === 8) {
        setTutorialStep(tutorialStep + 1);
      }
      return;
    }

    // 3D shape dunk, clear all held without transfer
    outputLog("3d shape held, dunking nothing and clearing held shapes");
    const newShapeMap = [...shapeMap].reduce((list, obj) => {
      return obj.isHeld === true
        ? [...list, { ...obj, isHeld: false, beenDunked: true }]
        : [...list, obj];
    }, []);
    setInputHistory([
      ...inputHistory,
      {
        isPlayer: true,
        shapePositions: shapeMap.reduce((heldShapes, shapeData, shapeIndex) => {
          return shapeData.isHeld ? [...heldShapes, shapeIndex] : heldShapes;
        }, []),
        originalStatueIndex: userPosition,
        statueIndex: playerDunkedStatueIndex,
        isSuccessful: false,
      },
    ]);

    setShapeMap(newShapeMap);
    return;
  };

  const markShapeToManuallyMove = (position) => {
    const newShapeMap = [...shapeMap];
    const shapeToUpdate = newShapeMap.findIndex(
      (shape) => shape.position === position
    );

    const oldValue = shapeMap[shapeToUpdate].isManualHighlighted;
    const updatedMapValues = newShapeMap.map((shape) => ({
      ...shape,
      isManualHighlighted: false,
    }));
    updatedMapValues[shapeToUpdate].isManualHighlighted = !oldValue;
    setShapeMap(updatedMapValues);
  };

  const markStatueToReceiveManualShape = (statuePosition) => {
    const shapeToUpdate = shapeMap.findIndex(
      (shape) => shape.isManualHighlighted
    );

    if (shapeToUpdate === -1) {
      return;
    }

    if (statuePosition === shapeMap[shapeToUpdate].index) {
      setInputHistory([
        ...inputHistory,
        {
          isPlayer: false,
          shapePositions: [shapeToUpdate],
          originalStatueIndex: statuePosition,
          statueIndex: statuePosition,
          isSuccessful: false,
        },
      ]);
      return;
    }

    const newShapeMap = [...shapeMap].map((shape) => ({
      ...shape,
      isManualHighlighted: false,
    }));
    newShapeMap[shapeToUpdate].index = statuePosition;
    newShapeMap[shapeToUpdate].hasTransferred = true;
    setInputHistory([
      ...inputHistory,
      {
        isPlayer: false,
        shapePositions: [shapeToUpdate],
        originalStatueIndex: shapeMap[shapeToUpdate].index,
        statueIndex: statuePosition,
        isSuccessful: true,
      },
    ]);
    updateGivenAwayCount(givenAwayCount + 1);
    updateThisTimeGivenAwayCount(thisTimeGivenAwayCount + 1);
    updateShapesMovedByTeam(shapesMovedByTeam + 1);
    setShapeMap(newShapeMap);
  };

  const killOgre = () => {
    setKnightKillCount(0);
    const newKnightOptions = [...currentKnightOptions];
    const oldKnightOptions = newKnightOptions.splice(0, 2);
    const knightOptions = [...newKnightOptions, ...oldKnightOptions];
    setCurrentKnightOptions(
      [...knightOptions].filter((option) => option !== "")
    );
    setShapeMap([
      ...shapeMap.map((shape) => ({
        ...shape,
        isKnightKilled: false,
        beenDunked: false,
      })),
    ]);
  };

  const markAsRevived = () => {
    setEncounterTimer(Math.min(reviveTime, encounterTimer));
    setShapeMap(
      [...shapeMap].map((shapeData) => ({ ...shapeData, isHeld: false }))
    );
    setHasRevived(true);
  };

  const escapeEncounter = () => {
    const newShapeMap = [...shapeMap].reduce((fullMap, obj) => {
      if (obj.index !== userPosition) {
        return [...fullMap, obj];
      }
      return [...fullMap, { ...obj, isHeld: false }];
    }, []);

    setShapeMap(newShapeMap);
    setHasEscaped(true);
  };

  const updateTutorialStep = (nextStep, direction) => {
    if (tutorialStep === 6 && direction === "next") {
      return;
    }

    setTutorialStep(nextStep ?? 1);
  };

  const updateRowActionOverlay = (
    inputHistoryIndex,
    shape,
    fromStatue,
    toStatue,
    isSuccessful
  ) => {
    if (inputHistoryIndex === rowActionOverlay?.inputHistoryIndex) {
      return setRowActionOverlay(null);
    }

    setRowActionOverlay({
      inputHistoryIndex,
      shape,
      fromStatue,
      toStatue,
      isSuccessful,
    });
  };

  const renderTutorial = () => {
    if (tutorialStep === null) {
      return "";
    }

    const [stepContent, totalStepCount] = buildTutorialSteps(
      userPosition,
      positions,
      tutorialStep - 1
    );

    const tutorialControls = [
      <span className="prev-step"></span>,
      <span className="tutorial-progress">
        Step {tutorialStep} of {totalStepCount}
      </span>,
      <span className="prev-step"></span>,
    ];

    if (tutorialStep > 1) {
      tutorialControls[0] = (
        <button
          className="prev-step"
          onClick={() => updateTutorialStep(tutorialStep - 1, "previous")}
        >
          Previous
        </button>
      );
    }

    if (tutorialStep < totalStepCount) {
      tutorialControls[2] = (
        <button
          className={`next-step ${tutorialStep === 6 ? "disabled" : ""}`}
          onClick={() => updateTutorialStep(tutorialStep + 1, "next")}
        >
          Next
        </button>
      );
    }

    return (
      <div className="tutorial-wrapper">
        <img
          className="close-tutorial"
          onMouseUp={() => setTutorialStep(null)}
          src={images.cross}
          alt="Close Control Panel"
        />
        <h2>Tutorial</h2>
        {stepContent}
        <div className="tutorial-controls">{tutorialControls}</div>
      </div>
    );
  };

  const renderStatues = () => {
    return statueMap.map((statue, index) => {
      const heldShape = shapeMap.find((shapeData) => shapeData.isHeld);
      const isUserStatue = userPosition === index;
      const tutorialStatueToDunkOn =
        heldShape?.symbol === statue
          ? statueMap.find((statueValue) => statueValue !== statue)
          : statueMap.find((statueValue) => statueValue === statue);

      const showTutorialIcon =
        (tutorialStep === 4 && userPosition === index) ||
        (tutorialStep === 5 && userPosition !== index) ||
        (tutorialStep === 8 &&
          index !== userPosition &&
          tutorialStatueToDunkOn === statue);
      return (
        <Statue
          statue={statue}
          statuePosition={index}
          isUser={isUserStatue}
          isManualMode={insideSimMode === "manual"}
          renderHeldShapes={debug || insideSimMode === "manual"}
          shapeMap={shapeMap}
          dunkHeldShapes={dunkHeldShapes}
          showTutorialIcon={showTutorialIcon}
          markShapeToManuallyMove={markShapeToManuallyMove}
          markStatueToReceiveManualShape={markStatueToReceiveManualShape}
        />
      );
    });
  };

  const renderInputOverlay = () => {
    if (rowActionOverlay === null) {
      return;
    }
    const { fromStatue, toStatue, shape, isSuccessful, inputHistoryIndex } =
      rowActionOverlay;

    return (
      <div
        className={`input-history-overlay from-${positions[fromStatue]} to-${positions[toStatue]}`}
      >
        <div
          className={`overlay-pointer-arrow from-${positions[fromStatue]} to-${positions[toStatue]}`}
        ></div>
        {inputHistoryIndex + 1}.
        <img
          className={`overlay-success-state ${isSuccessful ? "green" : "red"}`}
          src={isSuccessful ? images.checkmark : images.cross}
        />
        <img className="overlay-symbol" src={images[shape]} />
      </div>
    );
  };

  const renderOgre = () => {
    if (insideSimMode === "manual") {
      return;
    }

    const availableKnightShapeOptions = shapeMap.filter(
      (shape) =>
        shape.index === userPosition &&
        shape.isKnightKilled === false &&
        shape.beenDunked === false &&
        currentKnightOptions.includes(shape.position)
    );
    if (
      availableKnightShapeOptions.length === 0 ||
      currentKnightOptions.slice(0, 2).every((shape) => shape === "") ||
      knightKillCount === 2
    ) {
      return (
        <div className="ogre-option">
          <Ogre killOgre={killOgre} showTutorialIcon={tutorialStep === 10} />
        </div>
      );
    }
  };

  const renderKnights = () => {
    if (insideSimMode === "manual") {
      return;
    }

    const firstTwoKnightOptions = currentKnightOptions.slice(0, 2);
    const matchingShapes = [...shapeMap]
      .sort(
        (a, b) =>
          currentKnightOptions.indexOf(a.position) -
          currentKnightOptions.indexOf(b.position)
      )
      .filter(
        (shape) =>
          shape.beenDunked === false &&
          firstTwoKnightOptions.includes(shape.position) &&
          shape.index === userPosition
      )
      .slice(0, 2);
    const knights = matchingShapes.map((shapeData, index) => {
      const hasMaxHeldShapes =
        shapeMap.filter((shapeData) => shapeData.isHeld === true).length === 2;
      return (
        <Knight
          key={`knight-${shapeData.position}`}
          shapeData={shapeData}
          index={index}
          hasMaxHeldShapes={hasMaxHeldShapes}
          markShapeAsHeld={markShapeAsHeld}
          killKnight={killKnight}
          renderDebugData={debug}
          showTutorialIcon={
            tutorialStep === 6 &&
            (shapeData.symbol !== statueMap[userPosition] ||
              shapeMap.every(
                (knightShape) => knightShape.symbol === shapeData.symbol
              ))
          }
        />
      );
    });

    return <div className="knight-options">{knights}</div>;
  };

  const renderUserStartingShapes = (shapeMapArray) => {
    return shapeMapArray
      .filter((shape) => shape.startIndex === userPosition)
      .map((shape, shapeIndex) => (
        <div
          key={`startingShape-${shape.symbol}-${shapeIndex}`}
          className={`${shape.symbol} tiny startingshape${shapeIndex}`}
        ></div>
      ));
  };

  const renderHeldShapes = () => {
    const heldShapes = shapeMap.filter((shapeData) => shapeData.isHeld);
    if (heldShapes.length === 0) {
      return;
    }

    const [renderShape, renderText] = calculateHeldShape(heldShapes);
    return (
      <Buff
        shape={renderShape}
        text={renderText}
        showTutorialIcon={tutorialStep === 7}
      />
    );
  };

  const renderSuccess = () => {
    return (
      <div className="success-data">
        <p>
          {t("simulator.summary.escapedIn", {
            time: encounterStartTimeSeconds - encounterTimer,
          })}
        </p>
        <p>
          {t("simulator.summary.gaveAway", {
            givenAway: thisTimeGivenAwayCount,
            count: thisTimeGivenAwayCount,
          })}
        </p>
        <p>
          {t("simulator.summary.successSession", {
            success: successCount,
            count: successCount,
          })}
        </p>
        <p>
          {t("simulator.summary.successStreak", {
            streak: successStreak,
          })}
        </p>
      </div>
    );
  };

  const renderModeMessage = () => {
    const instructions = [];

    if (insideSimMode === "manual") {
      instructions.push(
        <p>{t("simulator.instructions.manualInstruction1")}</p>
      );
    }

    if (insideSimMode === "automatic") {
      instructions.push(
        <p>{t("simulator.instructions.automaticInstruction1")}</p>,
        <p>{t("simulator.instructions.automaticInstruction2")}</p>,
        <p>{t("simulator.instructions.automaticInstruction3")}</p>
      );
    }

    return (
      <div className="mode-notification">
        <p>
          {t("simulator.instructions.enabled", {
            mode: t(`simulator.${insideSimMode}`),
          })}
        </p>
        {instructions}
      </div>
    );
  };

  const renderDebugData = () => {
    if (debug === false) {
      return "";
    }

    return (
      <div className="simulator-data">
        <div>{parseInt(encounterTimer, 10)} secs</div>
        <div>{`timePaused: ${pauseTime}`}</div>
        <div>{`hasFailed: ${hasFailed}`}</div>
        <div>{`hasEscaped: ${hasEscaped}`}</div>
        <div>{`hasCompleted: ${hasCompleted}`}</div>
        <div>{`hasFinalized: ${hasFinalized}`}</div>
        <div>{`hasRevived: ${hasRevived}`}</div>
        <div>{`successCount: ${successCount}`}</div>
        <div>{`successStreak: ${successStreak}`}</div>
        <div>{`allShapesMatchingStatue: ${allShapesMatchingStatue}`}</div>
        <div>{`shapesMovedByTeam: ${shapesMovedByTeam}`}</div>
        <div>{`Revive at : ${reviveTime}`}</div>
        <div>
          Shapes given away:
          {givenAwayCount}
        </div>
        <div className="" style={{ width: "10rem", height: "25rem" }}>
          <h3>Goal Shapes</h3>
          {shapes
            .filter((shape) => shape !== statueMap[userPosition])
            .map((shape, shapeIndex) => (
              <div
                key={`goalShapeShape-${shape}-${shapeIndex}`}
                className={`${shape} tiny`}
              ></div>
            ))}
        </div>
        <div style={{ marginTop: "2rem", width: "10rem", height: "25rem" }}>
          <h3>Starting Shapes</h3>
          {renderUserStartingShapes(shapeMap)}
        </div>
        <div>
          <button
            onClick={() => {
              setShapeMap(
                shapeMap.map((shape) => ({ ...shape, index: userPosition }))
              );
              setCurrentKnightOptions(shapeMap.map((shape) => shape.position));
            }}
          >
            Set all shapes to user
          </button>
        </div>
        <div>{currentKnightOptions.join()}</div>
      </div>
    );
  };

  if (hasCompleted) {
    return (
      <>
        {renderSuccess()}
        <div className="reset-main">
          <Reset hasCompleted={hasCompleted} onReset={resetEncounter} />
        </div>
      </>
    );
  }

  const renderChecklist = () => {
    const steps = [
      {
        text: t("simulator.checklist.allShapesMatching"),
        check: allShapesMatchingStatue,
      },
      {
        text: t("simulator.checklist.allShapesTransferred"),
        check: Boolean(
          shapeMap.filter((shapeData) => !shapeData.hasTransferred).length === 0
        ),
      },
      {
        text: t("simulator.checklist.allShapesSet"),
        check: allShapesSetCorrectly,
      },
    ];

    return (
      <div className="simulator-checklist">
        {steps.map((step, stepIndex) => {
          return (
            <div className="" key={`checklist-step-${stepIndex}`}>
              <span>{step.text}</span>
              <img
                className={`checklist-icon ${step.check ? "green" : "red"}`}
                src={step.check ? images.checkmark : images.cross}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {debug === true && (
        <button onClick={() => iterateNPCStatues()}>Progress Actions</button>
      )}
      {renderDebugData()}
      {renderChecklist()}
      {renderModeMessage()}
      <div className="reset-main">
        <Reset hasCompleted={hasCompleted} onReset={resetEncounter} />
      </div>
      <Timer
        timer={encounterTimer}
        descriptor={t("simulator.timerMessage")}
        iconClassName={"imminent-end-icon"}
        showTutorialIcon={tutorialStep === 2}
      />
      {renderTutorial()}
      {renderKnights()}
      {renderOgre()}
      {allShapesSetCorrectly &&
        (isHoldingAllShapes || insideSimMode === "manual") && (
          <div className="escape-option">
            <button
              className="highlight-pulse"
              onClick={() => escapeEncounter()}
            >{`${t("simulator.escape")}!`}</button>
          </div>
        )}
      <DeathScreen
        hasFailed={hasFailed}
        hasFinalized={hasFinalized}
        hasRevived={hasRevived}
        onReset={resetEncounter}
        markAsRevived={markAsRevived}
      />
      {
        <WallShadow
          rotationActive={rotationActive}
          activeShape={activeShape}
          showTutorialIcon={tutorialStep === 3}
        />
      }
      <div className="statue-list">
        {renderStatues()}
        {renderInputOverlay()}
      </div>
      {renderHeldShapes()}
      <InputHistory
        data={inputHistory}
        shapeMap={shapeMap}
        statueMap={statueMap}
        highlightedRow={rowActionOverlay?.inputHistoryIndex}
        onRowClick={updateRowActionOverlay}
      />
      {/* <div className="tutorial-option" onClick={() => updateTutorialStep()}>
        <span>Help</span>
      </div> */}
    </>
  );
}
