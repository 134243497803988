export default {
  translation: {
    shapes: {
      descriptor: "shape",
      square: "Square",
      circle: "Circle",
      triangle: "Triangle",
      cube: "Cube",
      sphere: "Sphere",
      pyramid: "Pyramid",
      cylinder: "Cylinder",
      prism: "Prism",
      cone: "Cone",
      settings: "Settings",
      none: "None",
    },
    positions: {
      inside: "Inside",
      outside: "Outside",
      left: "Left",
      middle: "Middle",
      right: "Right",
      dissect: "Dissect",
      solution: "Solution",
      step: "Creates",
      intoleft: "into Left",
      intomiddle: "into Middle",
      intoright: "into Right",
    },
    control: {
      title: "Control Panel",
      reset: "Reset",
      text: "text",
      showVisualSteps: "Use visual steps",
      showTextSteps: "Use text steps",
      show: "Show",
      hide: "Hide",
      language: "Language",
      npcSwitchRate: "Solo Simulator NPC Switch rate",
      every: "every",
      seconds: "seconds",
      insideSimModeOption: "Inside simulator mode",
      automatic: "Use automatic mode",
      manual: "Use manual mode",
      calculatorViewMode: "Calculator solution",
    },
    simulator: {
      escape: "Escape",
      killKnight: "Kill",
      knight: "Knight",
      ogre: "Ogre",
      offerShape: "Offer Shape",
      timerMessage: "Imminent End",
      deathScreen: {
        notification: "You are dead... awaiting revive",
        skipButton: "Skip to Revived state",
      },
      you: "You",
      automatic: "Automatic",
      manual: "Manual",
      instructions: {
        enabled: "{{mode}} mode has been enabled.",
        manualInstruction1:
          "Click on a shape underneath a statue to select it, then click on another statue to move it.",
        automaticInstruction1:
          "You have two NPC teammates who work on the LFG strategy: get matching shapes, then get shapes different to the statue.",
        automaticInstruction2:
          "Kill knights, then pick up their dropped shapes and send to your NPC teammates.",
        automaticInstruction3:
          "Once you have cleared your knights, kill the ogre to access the next set of shapes available to you.",
      },
      reset: "Reset encounter",
      startAgain: "Start again",
      action: {
        pickupShape: "Pickup {{shape}}",
        lefttoleft: "Left sent {{sentShape}} to itself",
        middletomiddle: "Middle sent {{sentShape}} to itself",
        righttoright: "Right sent {{sentShape}} to itself",
        lefttomiddle:
          "Left ({{sentStatue}}) sent {{sentShape}} to Middle ({{receivedStatue}}) statue",
        lefttoright:
          "Left ({{sentStatue}}) sent {{sentShape}} to Right ({{receivedStatue}}) statue",
        middletoleft:
          "Middle ({{sentStatue}}) sent {{sentShape}} to Left ({{receivedStatue}}) statue",
        middletoright:
          "Middle ({{sentStatue}}) sent {{sentShape}} to Right ({{receivedStatue}}) statue",
        righttoleft:
          "Right ({{sentStatue}}) sent {{sentShape}} to Left ({{receivedStatue}}) statue",
        righttomiddle:
          "Right ({{sentStatue}}) sent {{sentShape}} to Middle ({{receivedStatue}}) statue",
        youtoleft: "You sent {{sentShape}} to left ({{receivedStatue}}) statue",
        youtomiddle:
          "You sent {{sentShape}} to middle ({{receivedStatue}}) statue",
        youtoright:
          "You sent {{sentShape}} to right ({{receivedStatue}}) statue",
      },
      summary: {
        escapedIn: " Well done! You escaped in {{time}} seconds.",
        gaveAway_one: "You gave away {{givenAway}} shape",
        gaveAway_other: "You gave away {{givenAway}} shapes",
        successSession_one:
          "You have been successful {{success}} time this session.",
        successSession_other:
          "You have been successful {{success}} times this session.",
        successStreak: "Your current success streak is {{streak}}.",
      },
      checklist: {
        allShapesMatching: "Holding matching shapes?",
        allShapesTransferred: "Shapes moved from start position?",
        allShapesSet: "Statues have shapes to escape?",
      },
      buff: {
        quadrate: "Quadrate",
        orbicular: "Orbicular",
        trigon: "Trigon",
        cylindric: "Cylindric",
        trilateral: "Trilateral",
        conid: "Conid",
        cubic: "Cubic",
        spherical: "Spherical",
        pyramidial: "Pyramidial",
      },
    },
    misc: {
      title: "Salvation's Edge - Verity Calculator",
      subheader: "Verity Helper",
      createdBy: "Created by",
    },
    page: {
      title: "Salvation's Edge - Verity Helper",
      description:
        "A helper for Verity, the 4th encounter in Destiny 2's Salvation's Edge raid. Allows configuration for translated solutions in either text or visual format.",
    },
  },
};
