const circle = "circle";
const square = "square";
const triangle = "triangle";

export const shapes = Object.freeze([triangle, circle, square]);
export const positions = Object.freeze(["left", "middle", "right"]);

export const volumes = Object.freeze({
  cube: [square, square],
  pyramid: [triangle, triangle],
  sphere: [circle, circle],
  cylinder: [circle, square],
  prism: [square, triangle],
  cone: [circle, triangle],
});

export const calculateHeldShape = (shapes) => {
  const heldShapes = shapes.reduce((string, shapeData) => {
    return string + shapeData.symbol[0];
  }, "");

  if (heldShapes === "" || heldShapes.length > 2) {
    return;
  }

  let shape;
  let text;

  switch (heldShapes) {
    case "s":
      shape = "square";
      text = "quadrate";
      break;
    case "c":
      shape = "circle";
      text = "orbicular";
      break;
    case "t":
      shape = "triangle";
      text = "trigon";
      break;
    case "cs":
    case "sc":
      shape = "cylinder";
      text = "cylindric";
      break;
    case "st":
    case "ts":
      shape = "prism";
      text = "trilateral";
      break;
    case "ct":
    case "tc":
      shape = "cone";
      text = "conid";
      break;
    case "ss":
      shape = "cube";
      text = "cubic";
      break;
    case "cc":
      shape = "sphere";
      text = "spherical";
      break;
    case "tt":
      shape = "pyramid";
      text = "pyramidial";
      break;
    default:
      shape = "";
      break;
  }

  return [shape, text];
};

/*
Objective: Ensure three solid shapes do not contain described simple shapes

Step 1: input inside callouts (left to right, must be unique)

Step 2: input starting solid shapes (left to right, must total two of each simple shape across all three)

Step 3: iterate through swaps

*/

// Calculated output
export const calculateOutput = (insideSymbols) => {
  return insideSymbols.map((shape) =>
    Object.values(shapes)
      .filter((step) => step !== shape)
      .sort()
  );
};

// Helper functions
export const matchShapes = (values, shapesToMatch) => {
  const firstShapeIndex = values.findIndex((val) => val === shapesToMatch[0]);
  const secondShapeIndex = values.findIndex(
    (val, index) => index !== firstShapeIndex && val === shapesToMatch[1]
  );
  return [firstShapeIndex, secondShapeIndex];
};

export const translateSimpleShapeToHumanReadable = (shapeArr) => {
  const match = Object.entries(volumes).find(([key, values]) => {
    const [firstShape, secondShape] = matchShapes(values, shapeArr);
    return firstShape > -1 && secondShape > -1;
  });

  return match.join(" - ");
};

export const translateSimpleShapeToComplexShape = (shapeArr) => {
  const [symbol, components] = Object.entries(volumes).find(([key, values]) => {
    const [firstShape, secondShape] = matchShapes(values, shapeArr);
    return firstShape > -1 && secondShape > -1;
  });

  return typeof symbol === "undefined" ? "" : symbol;
};

// const outsideEndArrValues = Object.freeze(calculateOutput(insideCallValues));

/*
For each complex shape:
- Find any simple shapes that do not exist in the final solution
- Find other complex shapes that contain swap target
- Store swap action for each complex shape location (max. 2)
*/

export const calculateActionMatrix = (step, outsideEndArr) => {
  let total = 0;

  const matches = [[], [], []];
  const results = step.map((shapes) => [...shapes]);

  const allPureSymbols = step.every((symbol) => symbol[0] === symbol[1]);

  // Manual override of all same symbols causing infinite loop
  if (allPureSymbols) {
    matches[0] = [0, 0, step[0][0]];
    matches[2] = [2, 0, step[2][0]];
  } else {
    // Iterate each complex shape

    // Prefer immediately splitting a pure shape
    const pureShape = step.findIndex((shapes) => shapes[0] === shapes[1]);
    if (pureShape > -1) {
      matches[pureShape] = [pureShape, 0, step[pureShape][0]];
      total++;
    }

    step.forEach((shapes, index) => {
      if (total === 2 || matches[index].length > 0) {
        return;
      }

      // Do not touch this symbol, it is already set correctly
      if (
        shapes[0] !== shapes[1] &&
        outsideEndArr[index].includes(shapes[0]) &&
        outsideEndArr[index].includes(shapes[1])
      ) {
        return;
      }

      // Identical shape, do not attempt to switch as we'll get stuck in an infinite loop
      if (
        index < step[step.length - 1] &&
        step[index].includes(step[index - 1]?.[0]) &&
        step[index].includes(step[index - 1]?.[1])
      ) {
        return;
      }

      const firstShape = Object.values(outsideEndArr[index]).findIndex(
        (val) => val === shapes[0]
      );
      const secondShape = Object.values(outsideEndArr[index]).findIndex(
        (val, index) => index !== firstShape && val === shapes[1]
      );

      // Capture value and positions for inputs that do not match expected final shapes
      if (firstShape === -1) {
        matches[index] = [index, 0, shapes[0]];
      }

      if (secondShape === -1) {
        matches[index] = [index, 1, shapes[1]];
      }

      if (matches[index].length > 0) {
        total++;
      }
    });
  }

  // Flip matches values in place, to be played back into defined result locations
  const populatedMatches = matches.filter((match) => match.length > 0);
  if (populatedMatches.length === 0) {
    return [[], []];
  }

  [matches[populatedMatches[0][0]][2], matches[populatedMatches[1][0]][2]] = [
    matches[populatedMatches[1][0]][2],
    matches[populatedMatches[0][0]][2],
  ];

  matches.forEach(([arrIndex, swapIndex, swapValue], index) => {
    if (typeof arrIndex === "number") {
      results[index][swapIndex] = swapValue;
    }
  });

  // Uncomment this if needed to reverse step display
  [matches[populatedMatches[0][0]][2], matches[populatedMatches[1][0]][2]] = [
    matches[populatedMatches[1][0]][2],
    matches[populatedMatches[0][0]][2],
  ];

  return [matches.map((res) => res[2]), results.map((result) => result.sort())];
};

export const resultMatchesSolution = (result, solution) => {
  return result.reduce((comparisonMap, symbol, symInd) => {
    const symbolMatch = symbol.every((shape) => {
      const shapeInSolution = solution[symInd].includes(shape);

      const solutionShapeFilter = solution[symInd].filter(
        (s) => s === shape
      ).length;
      const resultShapeFilter = symbol.filter((s) => s === shape).length;
      const shapeSolutionCountMatch = solutionShapeFilter === resultShapeFilter;

      return shapeInSolution && shapeSolutionCountMatch;
    });

    return [...comparisonMap, symbolMatch];
  }, []);
};

export const buildExecutionStep = (
  step,
  solution,
  swaps = [],
  results = []
) => {
  if (resultMatchesSolution(step, solution).every(Boolean)) {
    return [swaps, results];
  }

  const [swap, result] = calculateActionMatrix(step, solution);

  return buildExecutionStep(
    result,
    solution,
    [...swaps, swap],
    [...results, result]
  );
};

export const isOverlappingShape = (selectedList, name) => {
  const checks = [];
  const values = Object.values(selectedList)
    .map((symbol) => {
      return symbol !== "" ? volumes[symbol] : "";
    })
    .flat();

  if (values.filter((value) => value === "square").length >= 1) {
    checks.push(name === "cube");
  }

  if (values.filter((value) => value === "square").length >= 2) {
    checks.push(["cylinder", "prism"].includes(name));
  }

  if (values.filter((value) => value === "circle").length >= 1) {
    checks.push(name === "sphere");
  }

  if (values.filter((value) => value === "circle").length >= 2) {
    checks.push(["cylinder", "cone"].includes(name));
  }

  if (values.filter((value) => value === "triangle").length >= 1) {
    checks.push(name === "pyramid");
  }

  if (values.filter((value) => value === "triangle").length >= 2) {
    checks.push(["prism", "cone"].includes(name));
  }

  return checks.some(Boolean);
};

// Taken from https://stackoverflow.com/a/46545530
// Only to be used for smaller arrays, for larger ones use https://stackoverflow.com/a/12646864
export const shuffleArray = (array) => {
  return [...array]
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const buildStartingStackValuesObject = (
  statueList,
  secondShapeArray
) => {
  const stackList = [];
  statueList.forEach((statueSymbol, index) => {
    stackList.push(
      {
        symbol: statueSymbol,
        startIndex: index,
        index,
        isHeld: false,
        isKnightKilled: false,
        isManualHighlighted: false,
        hasTransferred: false,
        beenDunked: false,
        position: stackList.length,
      },
      {
        symbol: secondShapeArray[index],
        startIndex: index,
        index,
        isHeld: false,
        isKnightKilled: false,
        isManualHighlighted: false,
        hasTransferred: false,
        beenDunked: false,
        position: stackList.length + 1,
      }
    );
  });

  return stackList;
};

export const pickUserHeldShapePositions = (shapes, userPosition) => {
  return shapes
    .filter((shape) => shape.index === userPosition)
    .map((shape) => shape.position);
};

export const dedupePopulatedArrayValues = (arr) => {
  return arr.reduce(
    (deduped, value, index, array) =>
      array.indexOf(value) === index || value === ""
        ? [...deduped, value]
        : deduped,
    []
  );
};
