import { Trans } from "react-i18next";

export default function Ogre(props) {
  const { killOgre, showTutorialIcon } = props;
  return (
    <div>
      {showTutorialIcon && (
        <div className="tutorial-pointer-arrow relative center down"></div>
      )}
      <button onClick={() => killOgre()}>
        <Trans i18nKey={"simulator.kill"}>Kill</Trans>{" "}
        <Trans i18nKey={"simulator.ogre"}>Ogre</Trans>
      </button>
    </div>
  );
}
