export default function WallShadow(props) {
  const { rotationActive, activeShape, showTutorialIcon } = props;
  const activeClass = rotationActive ? "rotate" : "";
  return (
    <div className="stack">
      {showTutorialIcon && (
        <div className="tutorial-pointer-arrow relative center down"></div>
      )}
      <div className={`stack-display ${activeClass} ${activeShape}`}></div>
    </div>
  );
}
