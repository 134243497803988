import { useTranslation } from "react-i18next";
import { volumes, translateSimpleShapeToComplexShape } from "../helper";
import Symbol from "./Symbol";

export default function Solution(props) {
  const { t } = useTranslation();
  const { symbolValues, isFinal } = props;

  if (
    !Array.isArray(symbolValues) ||
    symbolValues.some((value) => typeof value === "undefined" || value === "")
  ) {
    return "";
  }

  const symbol = translateSimpleShapeToComplexShape(symbolValues);

  return (
    <div className="step-wrapper">
      <h3 className={`step-title`}>
        {isFinal ? t("positions.solution") : t("positions.step")}
      </h3>
      <Symbol
        symbol={symbol}
        canSelect={false}
        isSelected={() => {}}
        isDisabled={() => {}}
        isFinal={isFinal}
        handleClick={() => {}}
        isInline={false}
      />
    </div>
  );
}
