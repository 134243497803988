import block from "./../../assets/images/block.svg";
import circle from "./../../assets/images/circle.svg";
import cone from "./../../assets/images/cone.svg";
import cube from "./../../assets/images/cube.svg";
import cylinder from "./../../assets/images/cylinder.svg";
import prism from "./../../assets/images/prism.svg";
import pyramid from "./../../assets/images/pyramid.svg";
import sphere from "./../../assets/images/sphere.svg";
import square from "./../../assets/images/square.svg";
import triangle from "./../../assets/images/triangle.svg";
import block from "./../../assets/images/block.svg";
import cross from "./../../assets/images/cross.svg";
import checkmark from "./../../assets/images/checkmark.svg";
import settings from "./../../assets/images/settings.svg";

const images = {
  block,
  cross,
  circle,
  cone,
  cube,
  cylinder,
  prism,
  pyramid,
  sphere,
  square,
  settings,
  triangle,
  none: block,
  checkmark,
};

export default images;
