import { useTranslation } from "react-i18next";
import { isOverlappingShape } from "../helper";
import Symbol from "./Symbol";

export default function OutsideSelect(props) {
  const { t } = useTranslation();
  const { handleOutsideSelected, selected, position } = props;

  const isSelected = (name) => {
    return selected[position] === name;
  };

  const isDisabled = (name) => {
    if (isSelected(name)) {
      return false;
    }

    if (isOverlappingShape(selected, name)) {
      return true;
    }

    return (
      Object.values(selected).filter((value) => value === name).length >= 2
    );
  };

  function handleClick(name) {
    if (!isDisabled(name)) {
      handleOutsideSelected(isSelected(name) ? "" : name, position);
    }
  }

  return (
    <>
      <h3 className={`select-title`}>{t("positions.outside")}</h3>
      <div className="select six">
        <Symbol
          symbol={"cube"}
          isSelected={isSelected}
          canSelect={true}
          isDisabled={isDisabled}
          handleClick={handleClick}
          isFinal={false}
          isInline={false}
        />
        <Symbol
          symbol={"sphere"}
          isSelected={isSelected}
          canSelect={true}
          isDisabled={isDisabled}
          handleClick={handleClick}
          isFinal={false}
          isInline={false}
        />
        <Symbol
          symbol={"pyramid"}
          isSelected={isSelected}
          canSelect={true}
          isDisabled={isDisabled}
          handleClick={handleClick}
          isFinal={false}
          isInline={false}
        />
        <Symbol
          symbol={"cylinder"}
          isSelected={isSelected}
          canSelect={true}
          isDisabled={isDisabled}
          handleClick={handleClick}
          isFinal={false}
          isInline={false}
        />
        <Symbol
          symbol={"prism"}
          isSelected={isSelected}
          canSelect={true}
          isDisabled={isDisabled}
          handleClick={handleClick}
          isFinal={false}
          isInline={false}
        />
        <Symbol
          symbol={"cone"}
          isSelected={isSelected}
          canSelect={true}
          isDisabled={isDisabled}
          handleClick={handleClick}
          isFinal={false}
          isInline={false}
        />
      </div>
    </>
  );
}
