import { useTranslation } from "react-i18next";
import { calculateHeldShape, positions } from "../../helper";
import images from "../../assets/images";

export default function InputHistory(props) {
  const { t } = useTranslation();
  const { data, statueMap, shapeMap, onRowClick, highlightedRow } = props;
  const inputs = data.map((inputRow, index) => {
    const {
      originalStatueIndex,
      statueIndex,
      shapePositions,
      isPlayer,
      isSuccessful,
    } = inputRow;
    let inputTextColor = "white";
    if (isPlayer) {
      inputTextColor = isSuccessful ? "green" : "red";
    }

    if (highlightedRow === index) {
      inputTextColor = "yellow";
    }

    const [calculatedShape] = calculateHeldShape(
      shapeMap.filter((shapeData, index) => shapePositions.includes(index))
    );

    const messageKey = isPlayer
      ? `simulator.action.youto${positions[statueIndex]}`
      : `simulator.action.${positions[originalStatueIndex]}to${positions[statueIndex]}`;
    const message = t(messageKey, {
      sentStatue: isPlayer
        ? t("simulator.you")
        : t(`shapes.${statueMap[originalStatueIndex]}`),
      sentShape: t(`shapes.${calculatedShape}`),
      receivedStatue: t(`shapes.${statueMap[statueIndex]}`),
    });

    const handleRowClick = () => {
      return onRowClick(
        index,
        calculatedShape,
        originalStatueIndex,
        statueIndex,
        isSuccessful
      );
    };

    return (
      <div
        key={`input-message-${index}`}
        onClick={handleRowClick}
        className={`input-row ${inputTextColor}`}
      >
        {index + 1}. {message}
        <img
          className={`input-success-state-icon ${
            isSuccessful ? "green" : "red"
          }`}
          src={isSuccessful ? images.checkmark : images.cross}
        />
      </div>
    );
  });

  return <div className="input-history">{inputs}</div>;
}
