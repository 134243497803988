import { useTranslation, Trans } from "react-i18next";
import { positions } from "../../helper";

export default function Statue(props) {
  const { t } = useTranslation();
  const {
    statue,
    isUser,
    renderHeldShapes,
    statuePosition,
    shapeMap,
    dunkHeldShapes,
    showTutorialIcon,
    markShapeToManuallyMove,
    isManualMode,
    markStatueToReceiveManualShape,
  } = props;

  const renderStatueIdentifier = () => {
    if (!isManualMode) {
      return isUser ? t("simulator.you") : "";
    }

    return t(`positions.${positions[statuePosition]}`);
  };

  return (
    <div key={`statue-${statue}`} className={`statue-wrapper ${statue}-statue`}>
      {showTutorialIcon && (
        <div className="tutorial-pointer-arrow absolute center down"></div>
      )}
      <div
        className={`small ${statue} ${isManualMode ? "statue-symbol" : ""}`}
        onClick={() => {
          markStatueToReceiveManualShape(statuePosition);
        }}
      >
        <span className="player-marker">{renderStatueIdentifier()}</span>
      </div>
      {!isUser && !isManualMode ? (
        <button onClick={() => dunkHeldShapes(statue)}>
          <Trans i18nKey="simulator.offerShape">Offer Shape</Trans>
        </button>
      ) : (
        ""
      )}
      {renderHeldShapes === true &&
        shapeMap
          .filter((shape) => shape.index === statuePosition)
          .map((shape, index) => (
            <div
              key={`shape-${shape.symbol}-${index}`}
              className={`${shape.symbol} tiny selectable ${
                shape.isManualHighlighted
                  ? "manual-highlight"
                  : "statue-shape-symbol"
              }`}
              onClick={() => markShapeToManuallyMove(shape.position)}
            ></div>
          ))}
    </div>
  );
}
