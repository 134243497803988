import { createRoot } from "react-dom/client";
import Root from "./routes/Root";
import { Calculator } from "./routes/Calculator";
import { InsideSimulator } from "./routes/InsideSimulator";
import ErrorPage from "./error-page";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./i18n/index";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Calculator />,
      },
      {
        path: "/inside-simulator",
        element: <InsideSimulator />,
      },
    ],
  },
]);

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<RouterProvider router={router} />);
