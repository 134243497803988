import { useTranslation } from "react-i18next";
import images from "../assets/images/";
const circle = "circle";
const square = "square";
const triangle = "triangle";

const shapes = [triangle, circle, square];

const volumes = {
  cube: [square, square],
  pyramid: [triangle, triangle],
  sphere: [circle, circle],
  cylinder: [circle, square],
  prism: [square, triangle],
  cone: [circle, triangle],
};

export default function Symbol(props) {
  const { t } = useTranslation();
  const {
    symbol,
    handleClick,
    isSelected,
    canSelect,
    isDisabled,
    isFinal = false,
    isInline = false,
    withText = true,
    withBrackets = false,
  } = props;

  const classes = [];

  if (isSelected(symbol)) {
    classes.push("selected");
  }

  if (isDisabled(symbol)) {
    classes.push("disabled");
  }

  if (isFinal) {
    classes.push("final");
  }

  if (isInline) {
    classes.push("inline");
  }

  const makeClassName = (type) => [...classes, type].join(" ");

  const renderImg = () => {
    return (
      <img
        key="shape-image"
        onMouseUp={() => handleClick(symbol)}
        className={makeClassName("symbol")}
        src={images[symbol]}
        alt={`${t(`shapes.${symbol}`)} ${t("shapes.descriptor")}`}
        title={t(`shapes.${symbol}`)}
      />
    );
  };

  if (isInline) {
    const inlineRenderMap = [];
    if (withText) {
      inlineRenderMap.push(<span key="shape">{t(`shapes.${symbol}`)}</span>);
    }

    if (withBrackets) {
      inlineRenderMap.push("(");
    }

    inlineRenderMap.push(renderImg());

    if (withBrackets) {
      inlineRenderMap.push(")");
    }

    return (
      <span
        className={`symbol-wrapper inline ${canSelect ? "selectable" : ""}`}
      >
        {inlineRenderMap}
      </span>
    );
  }

  return (
    <div
      className={`symbol-wrapper ${canSelect ? "selectable" : ""}`}
      onMouseUp={() => handleClick(symbol)}
    >
      <div name={symbol}>{renderImg()}</div>
      {withText && (
        <div className={makeClassName("text")}>{t(`shapes.${symbol}`)}</div>
      )}
    </div>
  );
}
