import { Trans } from "react-i18next";
import Reset from "../Reset";

export default function DeathScreen(props) {
  const { hasFailed, hasFinalized, hasRevived, onReset, markAsRevived } = props;
  if (hasFailed === true) {
    return (
      <div className="finalized-overlay">
        <span>Fireteam wiped...</span>
        <Reset hasCompleted={false} onReset={onReset} />
      </div>
    );
  }

  if (hasFinalized === true && hasRevived === false) {
    return (
      <div className="finalized-overlay">
        <span>
          <Trans i18nKey={"simulator.deathScreen.notification"}>
            You are dead... awaiting revive
          </Trans>
        </span>
        <button onClick={() => markAsRevived()}>
          <Trans i18nKey={"simulator.deathScreen.skipButton"}>
            Skip to Revived state
          </Trans>
        </button>
      </div>
    );
  }
}
