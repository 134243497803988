import { Trans } from "react-i18next";

export default function Footer() {
  return (
    <footer>
      <div className="created-by">
        <Trans i18nKey={`misc.createdBy`}>Created by</Trans> Jimsalad#9255
      </div>
    </footer>
  );
}
