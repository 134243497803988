import { Trans } from "react-i18next";

export default function Reset(props) {
  const { hasCompleted, onReset } = props;
  return (
    <button onClick={() => onReset()}>
      {hasCompleted ? (
        <Trans i18nKey="simulator.startAgain">Start Again</Trans>
      ) : (
        <Trans i18nKey="simulator.reset">Reset encounter</Trans>
      )}
    </button>
  );
}
