import { useTranslation } from "react-i18next";
import SoloSelect from "./SoloSelect";
import GroupSelect from "./GroupSelect";
import Step from "./Step";
import Solution from "./Solution";

export default function Side(props) {
  const { t } = useTranslation();
  const {
    position,
    handleInsideSelected,
    insideSelected,
    handleOutsideSelected,
    outsideSelected,
    endSolution,
    swapSteps,
    solutionChain,
    viewMode,
  } = props;

  const renderStepsAndSolutions = () => {
    if (viewMode !== "visual") {
      return "";
    }

    if (
      solutionChain.length === 0 &&
      Object.values(insideSelected).flat().length === 3 &&
      Object.values(outsideSelected)
        .flat()
        .filter((v) => v !== "").length === 3
    ) {
      return (
        <Solution
          symbolValues={endSolution[position]}
          viewMode={viewMode}
          isFinal={true}
        />
      );
    }
    if (swapSteps.length === 0 || swapSteps.length !== solutionChain.length) {
      return "";
    }

    if (
      swapSteps.length === 1 &&
      typeof swapSteps[0] === "undefined" &&
      solutionChain.length === 1 &&
      typeof solutionChain[0] === "undefined"
    ) {
      return (
        <Solution
          symbolValues={endSolution[position]}
          viewMode={viewMode}
          isFinal={true}
        />
      );
    }

    const renderList = [];

    swapSteps.forEach((step, index) => {
      const stepResult = solutionChain[index];
      renderList.push(<Step swapStep={step} viewMode={viewMode} />);
      renderList.push(
        <Solution
          symbolValues={stepResult}
          viewMode={viewMode}
          isFinal={swapSteps.length - 1 === index}
        />
      );
    });

    return renderList;
  };

  return (
    <div className={`${position} side`}>
      <h2 className={`side-title`}>{t(`positions.${position}`)}</h2>
      <SoloSelect
        handleInsideSelected={handleInsideSelected}
        position={position}
        selected={insideSelected}
      />
      <GroupSelect
        handleOutsideSelected={handleOutsideSelected}
        position={position}
        selected={outsideSelected}
      />
      {renderStepsAndSolutions()}
    </div>
  );
}
