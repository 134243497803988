import { useTranslation } from "react-i18next";
import { translateSimpleShapeToComplexShape, positions } from "../helper";
import Symbol from "./Symbol";

export default function InstructionSolution(props) {
  const { t } = useTranslation();
  const { solutionStep, isFinal } = props;
  1;
  const solutions = solutionStep.map((solution, index) => {
    const complexShape = translateSimpleShapeToComplexShape(solution);
    return (
      <div className="instruction-solution-side">
        {t(`positions.${positions[index]}`)}{" "}
        <Symbol
          symbol={complexShape}
          isSelected={() => {}}
          canSelect={false}
          isDisabled={() => {}}
          isFinal={isFinal}
          isInline={true}
          handleClick={() => {}}
          withText={true}
          withBrackets={true}
        />
      </div>
    );
  });

  return <div className="instruction-solution">{solutions}</div>;
}
