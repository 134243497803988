import { Trans, useTranslation } from "react-i18next";
import { outputLog } from "../../logger";

export default function Knight(props) {
  const { t } = useTranslation();
  const {
    shapeData,
    index,
    hasMaxHeldShapes,
    markShapeAsHeld,
    killKnight,
    showTutorialIcon,
  } = props;
  if (shapeData === "" || typeof shapeData === "undefined") {
    return;
  }

  if (shapeData.isHeld === true) {
    return <span key={`button-empty-${index}`}></span>;
  }

  const buttonText = () => {
    if (shapeData.isKnightKilled) {
      return (
        <>
          {t(`simulator.action.pickupShape`, {
            shape: t(`shapes.${shapeData.symbol}`),
          })}
        </>
      );
    }

    return (
      <>
        <Trans i18nKey={"simulator.kill"}>Kill</Trans>{" "}
        <Trans i18nKey={"simulator.knight"}>Knight</Trans>
      </>
    );
  };

  return (
    <div>
      {showTutorialIcon && (
        <div className="tutorial-pointer-arrow relative center down"></div>
      )}
      <button
        onClick={() => {
          if (shapeData.isKnightKilled === false) {
            return killKnight(shapeData.position);
          }

          if (hasMaxHeldShapes) {
            outputLog(
              "unable to pick up extra shape - already holding 3d shape"
            );
            return;
          }

          markShapeAsHeld(shapeData.position);
        }}
      >
        {buttonText()}
      </button>
    </div>
  );
}
