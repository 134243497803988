import "/src/styles/index.scss";
import i18n from "/src/i18n";
import { useState, useEffect } from "react";

import Header from "/src/components/Common/Header";
import Footer from "/src/components/Common/Footer";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Outlet, useOutletContext } from "react-router-dom";

export default function Root() {
  const { t } = useTranslation();
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [viewMode, setViewMode] = useState(
    localStorage.getItem("viewMode") ?? "visual"
  );
  const [npcSwitchRate, setNpcSwitchRate] = useState(
    localStorage.getItem("npcSwitchRate") ?? 5000
  );

  const [insideSimMode, setInsideSimMode] = useState(
    localStorage.getItem("insideSimMode") ?? "automatic"
  );

  const toggleViewMode = () => {
    const updatedValue = viewMode === "visual" ? "instruction" : "visual";
    localStorage.setItem("viewMode", updatedValue);
    setViewMode(updatedValue);
  };

  const toggleControlPanel = () => {
    setShowControlPanel(!showControlPanel);
  };

  const toggleInsideSimMode = () => {
    const updatedValue = insideSimMode === "automatic" ? "manual" : "automatic";
    localStorage.setItem("insideSimMode", updatedValue);
    setInsideSimMode(updatedValue);
  };

  const updateNpcSoloSimSwitchRate = (event) => {
    localStorage.setItem("npcSwitchRate", event.target.value);
    setNpcSwitchRate(event.target.value);
  };

  useEffect(() => {
    document.documentElement.lang = i18n.resolvedLanguage;
  }, [i18n.resolvedLanguage]);
  return (
    <>
      <Helmet>
        <title>{t("page.title")}</title>
        <meta name="description" content={t("page.description")} />
      </Helmet>
      <Header
        toggleViewMode={toggleViewMode}
        toggleControlPanel={toggleControlPanel}
        viewMode={viewMode}
        showControlPanel={showControlPanel}
        npcSwitchRate={npcSwitchRate}
        updateNpcSwitchRate={updateNpcSoloSimSwitchRate}
        insideSimMode={insideSimMode}
        toggleInsideSimMode={toggleInsideSimMode}
      />
      <Outlet
        context={{
          viewMode,
          npcSwitchRate,
          insideSimMode,
        }}
      />
      <Footer />
    </>
  );
}

export function useRootContext() {
  return useOutletContext();
}
