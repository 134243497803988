import { Trans, useTranslation } from "react-i18next";
import Symbol from "./Symbol";

export default function InsideSelect(props) {
  const { t } = useTranslation();
  const { handleInsideSelected, selected, position } = props;

  const isSelected = (name) => {
    return selected[position] === name;
  };

  const isDisabled = (name) => {
    if (isSelected(name)) {
      return false;
    }

    return Object.values(selected).includes(name);
  };

  function handleClick(name) {
    if (!isDisabled(name)) {
      handleInsideSelected(isSelected(name) ? "" : name, position);
    }
  }

  return (
    <>
      <h3 className={`select-title`}>{t("positions.inside")}</h3>
      <div className="select three">
        <Symbol
          symbol={"square"}
          isSelected={isSelected}
          canSelect={true}
          isDisabled={isDisabled}
          handleClick={handleClick}
          isFinal={false}
          isInline={false}
        />
        <Symbol
          symbol={"circle"}
          isSelected={isSelected}
          canSelect={true}
          isDisabled={isDisabled}
          handleClick={handleClick}
          isFinal={false}
          isInline={false}
        />
        <Symbol
          symbol={"triangle"}
          isSelected={isSelected}
          canSelect={true}
          isDisabled={isDisabled}
          handleClick={handleClick}
          isFinal={false}
          isInline={false}
        />
      </div>
    </>
  );
}
