import Side from "/src/components/Side";
import { useState, useEffect } from "react";
import { calculateOutput, buildExecutionStep, volumes } from "/src/helper";
import { useRootContext } from "./Root";
import { Trans, useTranslation } from "react-i18next";
import "/src/styles/index.scss";
import InstructionList from "/src/components/InstructionList";

const defaultEndSolutionState = Object.freeze({
  left: ["", ""],
  middle: ["", ""],
  right: ["", ""],
});

const defaultSymbolSelectState = Object.freeze({
  left: "",
  middle: "",
  right: "",
});

export function Calculator(props) {
  const { viewMode } = useRootContext();
  const { t } = useTranslation();
  const [insideSelected, setInsideSelected] = useState({
    ...defaultSymbolSelectState,
  });

  const [outsideSelected, setOutsideSelected] = useState({
    ...defaultSymbolSelectState,
  });

  const [swapSteps, setSwapSteps] = useState([]);
  const [solutionChain, setSolutionChain] = useState([]);
  const [endSolution, setEndSolution] = useState(
    structuredClone(defaultEndSolutionState)
  );

  useEffect(() => {
    const executeInsideSelectedCalc = () => {
      const insideValues = Object.values(insideSelected);
      const fullValueList =
        insideValues.filter((value) => value !== "").length === 3;
      if (fullValueList) {
        const [left, middle, right] = calculateOutput(insideValues);
        setEndSolution({ left, middle, right });
      } else {
        setEndSolution(structuredClone(defaultEndSolutionState));
      }
    };
    executeInsideSelectedCalc();
  }, [insideSelected]);

  useEffect(() => {
    const executeBuildSteps = () => {
      const insideValues = Object.values(insideSelected);
      const outsideValues = Object.values(outsideSelected);
      const endSolutionValues = Object.values(endSolution).flat();

      const fullValueList =
        outsideValues.filter((value) => value !== "").length === 3 &&
        insideValues.filter((value) => value !== "").length === 3 &&
        endSolutionValues.filter((value) => value !== "").length === 6;
      if (!fullValueList) {
        setSwapSteps([]);
        setSolutionChain([]);
        return;
      }

      const [swaps, results] = buildExecutionStep(
        outsideValues.map((value) => volumes[value]),
        Object.values(endSolution)
      );

      setSwapSteps(swaps);
      setSolutionChain(results);
    };
    executeBuildSteps();
  }, [insideSelected, outsideSelected, endSolution]);

  const resetToDefaultState = () => {
    setInsideSelected({ ...defaultSymbolSelectState });
    setOutsideSelected({ ...defaultSymbolSelectState });
    setSwapSteps([]);
    setSolutionChain([]);
    setEndSolution(structuredClone(defaultEndSolutionState));
  };

  const handleInsideSelected = (name, position) => {
    setInsideSelected({ ...insideSelected, [position]: name });
  };

  const handleOutsideSelected = (name, position) => {
    setOutsideSelected({ ...outsideSelected, [position]: name });
  };

  return (
    <>
      <div className="side-wrapper">
        <button className="reset" onMouseUp={() => resetToDefaultState()}>
          <Trans i18nKey={"control.reset"}>Reset</Trans>
        </button>
        <Side
          position="left"
          handleInsideSelected={handleInsideSelected}
          insideSelected={insideSelected}
          handleOutsideSelected={handleOutsideSelected}
          outsideSelected={outsideSelected}
          endSolution={endSolution}
          swapSteps={swapSteps.map((stepArr) => stepArr[0])}
          solutionChain={solutionChain.map((stepArr) => stepArr[0])}
          viewMode={viewMode}
        />
        <Side
          position="middle"
          handleInsideSelected={handleInsideSelected}
          insideSelected={insideSelected}
          handleOutsideSelected={handleOutsideSelected}
          outsideSelected={outsideSelected}
          endSolution={endSolution}
          swapSteps={swapSteps.map((stepArr) => stepArr[1])}
          solutionChain={solutionChain.map((stepArr) => stepArr[1])}
          viewMode={viewMode}
        />
        <Side
          position="right"
          handleInsideSelected={handleInsideSelected}
          insideSelected={insideSelected}
          handleOutsideSelected={handleOutsideSelected}
          outsideSelected={outsideSelected}
          endSolution={endSolution}
          swapSteps={swapSteps.map((stepArr) => stepArr[2])}
          solutionChain={solutionChain.map((stepArr) => stepArr[2])}
          viewMode={viewMode}
        />
        <InstructionList
          viewMode={viewMode}
          swapSteps={swapSteps}
          solutionChain={solutionChain}
        />
      </div>
    </>
  );
}
