const shouldOutputLogs = false;

export const outputLog = (message) => {
  if (!shouldOutputLogs) {
    return;
  }
  if (typeof message === "undefined") {
    console.log();
    return;
  }

  if (Array.isArray(message)) {
    message.forEach(console.log);
    return;
  }

  console.log(message);
};

export default {
  outputLog,
};
