import { useTranslation } from "react-i18next";
import Symbol from "./Symbol";

export default function Step(props) {
  const { t } = useTranslation();
  const { swapStep } = props;

  return (
    <div className={`step-wrapper`}>
      <h3 className={`step-title`}>{t("positions.dissect")}</h3>
      <Symbol
        symbol={typeof swapStep === "undefined" ? "none" : swapStep}
        isSelected={() => {}}
        canSelect={false}
        isDisabled={() => {}}
        isFinal={false}
        handleClick={() => {}}
        isInline={false}
      />
    </div>
  );
}
