export default {
  translation: {
    shapes: {
      descriptor: "forma",
      square: "Cuadrado",
      circle: "Círculo",
      triangle: "Triángulo",
      cube: "Cubo",
      sphere: "Esfera",
      pyramid: "Pirámide",
      cylinder: "Cilindro",
      prism: "Prisma",
      cone: "Cono",
      settings: "Ajustes",
      none: "Ninguno",
    },
    positions: {
      inside: "Adentro",
      outside: "Afuera",
      left: "Izquierda",
      middle: "Centro",
      right: "Derecha",
      dissect: "Diseccionar",
      solution: "Solución",
      step: "Crea",
      intoleft: "a la Izquierda",
      intomiddle: "en el Centro",
      intoright: "a la Drerecha",
    },
    control: {
      title: "Panel de control",
      reset: "Reiniciar",
      text: "texto",
      showVisualSteps: "Utilice pasos visuales",
      showTextSteps: "Utilice pasos de texto",
      show: "Mostrar",
      hide: "Ocultar",
      language: "Idioma",
      npcSwitchRate: "Tasa de cambio de NPC en Solo Simulator",
      every: "cada",
      seconds: "segundos",
      insideSimMode: "{{type}} inside simulator",
      automatic: "Utilice el modo automático",
      manual: "Utilice el modo manual",
      calculatorViewMode: "Solución de calculadora",
    },
    simulator: {
      escape: "Escapar",
      killKnight: "Matar",
      knight: "Caballero",
      ogre: "Ogro",
      timerMessage: "Fin inminente",
      offerShape: "Forma de oferta",
      deathScreen: {
        notification: "Estas muerto ... esperando revivir",
        skipButton: "Saltar al estado revivido",
      },
      you: "Tú",
      automatic: "Automático",
      manual: "Manual",
      instructions: {
        enabled: "Se ha habilitado el modo {{mode}}.",
        manualInstruction1:
          "Haga clic en una forma debajo de una estatua para seleccionarla, luego haga clic en otra estatua para moverla.",
        automaticInstruction1:
          "Tienes dos compañeros de equipo NPC que trabajan en la estrategia LFG: obtener formas iguales y luego obtener formas diferentes a la estatua.",
        automaticInstruction2:
          "Mata a los caballeros, luego recoge las formas que cayeron y envíalas a tus compañeros de equipo NPC.",
        automaticInstruction3:
          "Una vez que hayas eliminado a tus caballeros, mata al ogro para acceder al siguiente conjunto de formas disponible para ti.",
      },
      reset: "Reiniciar encuentro",
      startAgain: "Empezar de nuevo",
      action: {
        pickupShape: "{{shape}} de recogida",
        lefttoleft: "Izquierda envió {{sentShape}} hacia sí mismo",
        middletomiddle: "Centro envió {{sentShape}} hacia sí mismo",
        righttoright: "Derecha envió {{sentShape}} hacia sí mismo",
        lefttomiddle:
          "La izquierda ({{sentStatue}}) envió un {{sentShape}} a la estatua del medio ({{receivedStatue}})",
        lefttoright:
          "Izquierda ({{sentStatue}}) envió {{sentShape}} a la estatua derecha ({{receivedStatue}})",
        middletoleft:
          "Centro ({{sentStatue}}) envió {{sentShape}} a la estatua de la izquierda ({{receivedStatue}})",
        middletoright:
          "Medio ({{sentStatue}}) enviado {{sentShape}} a la derecha ({{receivedStatue}}) estatua",
        righttoleft:
          "Derecha ({{sentStatue}}) envió {{sentShape}} to izquierda ({{receivedStatue}}) estatua",
        righttomiddle:
          "Derecha ({{sentStatue}}) envió {{sentShape}} a la estatua del medio ({{receivedStatue}})",
        youtoleft:
          "Enviaste {{sentShape}} a la estatua de la izquierda ({{receivedStatue}})",
        youtomiddle:
          "Enviaste {{sentShape}} a la estatua del medio ({{receivedStatue}})",
        youtoright:
          "Enviaste {{sentShape}} a la estatua de la derecha ({{receivedStatue}})",
      },
      summary: {
        escapedIn: "¡Bien hecho! Escapaste en {{time}} segundos.",
        gaveAway_one: "Regalaste {{givenAway}} formas",
        gaveAway_other: "Regalaste {{givenAway}} forma",
        successSession_one: "Has tenido éxito {{success}} vez en esta sesión.",
        successSession_other:
          "Has tenido éxito {{success}} veces en esta sesión.",
        successStreak: "Tu racha de éxito actual es {{streak}}.",
      },
      checklist: {
        allShapesMatching: "¿Sosteniendo formas coincidentes?",
        allShapesTransferred: "¿Formas movidas desde la posición inicial?",
        allShapesSet: "¿Las estatuas tienen formas para escapar?",
      },
      buff: {
        quadrate: "Cuadrada",
        orbicular: "Orbicular",
        trigon: "trígono",
        cylindric: "Cilíndrica",
        trilateral: "Trilátero",
        conid: "Cónido",
        cubic: "Cúbica",
        spherical: "Esférica",
        pyramidial: "Piramidal",
      },
    },
    misc: {
      subheader: "Veracidad (Ayudante)",
      createdBy: "Creado por",
    },
    page: {
      title: "Filo de la Salvación - Veracidad (Ayudante)",
      description:
        "Un ayudante para Veracidad, el cuarto encuentro en la incursión Salvation's Edge de Destiny 2. Permite la configuración de soluciones traducidas en formato texto o visual.",
    },
  },
};
