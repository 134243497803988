const secondsToMinutesAndSeconds = (input) => {
  const minutes = Math.floor(input / 60);
  const seconds = input % 60;
  return { minutes, seconds };
};

export default function Timer(props) {
  const { timer, descriptor, iconClassName, showTutorialIcon } = props;
  if (timer === 0) {
    return "";
  }
  const { minutes, seconds } = secondsToMinutesAndSeconds(timer);
  const countdownString = `${minutes}${":"}${seconds
    .toString()
    .padStart(2, "0")}`;
  return (
    <div className="countdown-timer">
      <span className="time">{countdownString}</span>
      <span className={iconClassName}>
        <span className="inner"></span>
      </span>
      <span className="descriptor">{descriptor}</span>
      {showTutorialIcon && (
        <div className="tutorial-pointer-arrow absolute up"></div>
      )}
    </div>
  );
}
