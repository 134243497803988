import images from "../../assets/images";
import { useTranslation } from "react-i18next";

export default function Buff(props) {
  const { shape, text, showTutorialIcon } = props;
  const { t } = useTranslation();
  return (
    <div className="buff">
      <span className="buff-icon">
        <span className="inner">
          <img
            key="shape-image"
            src={images[shape]}
            alt={`${t(`shapes.${shape}`)} ${t("shapes.descriptor")}`}
            title={t(`shapes.${shape}`)}
          />
        </span>
      </span>
      <span>{t(`simulator.buff.${text}`)}</span>
      {showTutorialIcon && (
        <div className="tutorial-pointer-arrow relative left"></div>
      )}
    </div>
  );
}
