import { positions, translateSimpleShapeToComplexShape } from "../helper";
import InstructionSolution from "./InstructionSolution";
import InstructionStep from "./InstructionStep";
import Symbol from "./Symbol";

export default function InstructionList(props) {
  const { swapSteps, solutionChain, viewMode } = props;

  if (viewMode === "visual") {
    return "";
  }

  const renderSwapSteps = () => {
    let knightKillCount = 0;
    return swapSteps.reduce((list, step, stepIndex) => {
      const actions = step.reduce((actionList, action, index) => {
        if (typeof action === "undefined") {
          return actionList;
        }

        // let ogre = "";
        // if (knightKillCount < 3) {
        //   knightKillCount++;
        // }
        // if (knightKillCount === 3 && stepIndex !== solutionChain.length - 1) {
        //   knightKillCount = 0;
        //   ogre = <li>Kill Ogres</li>;
        // }
        return [
          ...actionList,
          <InstructionStep symbol={action} position={positions[index]} />,
          //   ogre,
        ];
      }, []);

      if (solutionChain.length === stepIndex) {
        actions.pop();
      }

      return [
        ...list,
        ...actions,
        <InstructionSolution
          solutionStep={solutionChain[stepIndex]}
          isFinal={stepIndex === swapSteps.length - 1}
        />,
      ];
    }, []);
  };

  return <ol className="instruction-list">{renderSwapSteps()}</ol>;
}
